/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React from "react"
import { Link } from "gatsby"
import "../css/style.css"
import blog from "../images/blog-bg.png"

const Speak = () => {
  return (
    <section className="skew-bg speak-wrapper" id="speak">
      <div className="container-lg container-md">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <span>Let's work together</span>
            <h1 className="main-title">
             We love to listen to your requirements
            </h1>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="blog-bg">
              <Link to="/#contact" className="btn-1 learn-more-btn">
                Let's Talk
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Speak
