/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React from "react"
import "../css/style.css"

const SinglePageHeader = ({expertiseType}) => {
    return (
      <div className="detail-wrapper">
        
          {
              console.log("expertiseType",expertiseType)
          }
        {expertiseType === "mobile-solutions" ? (
          <section className="skew-bg mobile-detail darken" id="mobile-detail">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">Mobile Solutions</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "web-solutions" ? (
          <section className="skew-bg web-detail darken" id="web-detail">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">Web Solutions</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "cloud-solutions" ? (
          <section className="skew-bg cloud-detail darken" id="cloud-detail">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">Cloud Solutions</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "qa-solutions" ? (
          <section className="skew-bg qa-detail darken" id="qa-detail">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">QA / Software Testing</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "yahooSpreets" ? (
          <section className="skew-bg project-1 darken" id="project-1">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">Yahoo Spreets</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "launchpad" ? (
          <section className="skew-bg project-2 darken" id="project-2">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">Launchpad</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "civilAviationAuthority" ? (
          <section className="skew-bg project-3 darken" id="project-3">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">
                      UAE Civil Aviation Authority
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "binu" ? (
          <section className="skew-bg project-4 darken" id="project-4">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">BiNu</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "pillo" ? (
          <section className="skew-bg project-5 darken" id="project-5">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">Pillo</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "pocketSports" ? (
          <section className="skew-bg project-6 darken" id="project-6">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">Pocket Sports</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "logisticPlatform" ? (
          <section className="skew-bg project-7 darken" id="project-7">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">A video logistic platform</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "yourMoo" ? (
          <section className="skew-bg project-8 darken" id="project-8">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">YourMoo</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "passpod" ? (
          <section className="skew-bg project-9 darken" id="project-9">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">Passpod</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "betterClinics" ? (
          <section className="skew-bg project-10 darken" id="project-10">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">Better Clinics</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "voip" ? (
          <section className="skew-bg project-11 darken" id="project-11">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">Dr Voip</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "kingContent" ? (
          <section className="skew-bg project-12 darken" id="project-12">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">King Content</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "ourworks" ? (
          <section className="skew-bg project-12 darken" id="project-12">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">Our works</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : expertiseType === "privacyPolicy" ? (
          <section className="skew-bg project-13 darken" id="project-13">
            <div className="container-lg container-md">
              <div className="row">
                <div className="col-md-12 col-sm-12 animate__animated animate__fadeInTop">
                  <div className="detail-content">
                    <h1 className="detail-title">Privacy policy</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <div></div>
        )}
      </div>
    )
}

export default SinglePageHeader
