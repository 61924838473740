/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React from "react"
import PropTypes from "prop-types"
import { useEffect } from "react"

import Header from "./header"
import Navbar from "./navbar"
import Footer from "./footer"
import "./layout.css"
import "./footer.css"
import "../css/style.css"
import "../css/bootstrap.min.css"
import SinglePageHeader from "../components/singlePageHeader"
import Speak from "../components/speak"

function scrollNavBar() {
  if (typeof window !== `undefined`) {
    const width = window.innerWidth
    if (width > 320) {
      const scroll = document.documentElement.scrollTop
      const headerElem = document.getElementsByClassName("main")[0]
      if (scroll >= 107) {
        if (headerElem) {
          headerElem.className += " header-sticky"
        }
      } else {
        if (headerElem) {
          headerElem.className = headerElem.className.split(" header-sticky")[0]
        }
      }
    }
  }
}
const Layout = ({ children, index, change }) => {
  useEffect(() => {
    const loader = document.getElementById("preloader")
    if (loader) {
      loader.style.visibility = "hidden"
    }
    if (typeof window !== `undefined`) {
      // Header Scrolling Set White Background
      window.addEventListener("scroll", function () {
        scrollNavBar()
      })
      scrollNavBar()
    }
  }, [])

  return (

      <div className="main">
        <Navbar />

        {change !== "singleHeader" ? (
          <Header
            siteTitle={children[0].props.title || "Xminds Infotech Pvt Ltd"}
          />
        ) : (
          <SinglePageHeader
            siteTitle={children[0].props.title || "Xminds Infotech Pvt Ltd"}
          />
        )}

        <div className="wrapper">
          <main>{children}</main>
        </div>
        <Footer />
      </div>

  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
