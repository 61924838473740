import PropTypes from "prop-types"
import React, { useRef } from "react"
import ReactPlayer from "react-player"
import { Accordion, Card, Button } from "react-bootstrap";
import "../css/bootstrap.min.css"
import "./header.css"
import AppModal from "../components/modal"

const Header = (index, data) => {
  const modalRef = useRef();
  const [showResults, setShowResults] = React.useState(true)
  const onClick = () => {
    setShowResults(false)
  }
  const clutchIcon = require(`../images/clutch/clutch-logo.png`);
  const mobileappIcon = require(`../images/clutch/mobile-app-award.png`);
  return (
  <>
    <header className="hero">
      <ReactPlayer
        url={require("../video/banner-2.mp4")}
        width="100%"
        height="100%"
        playing={true}
        muted
        loop
        className="video"
      />
      <div className="header-body">
        <div className="container-lg container-md">
          <div className="hero-main-wrap">
            <div className="hero-content">
              <h1 className="header-title">Apps <span className="header-light">development</span> done right</h1>
              <p className="lead">Trusted by over 200 startups and other businesses. An agile shop for excellence in web and mobile application development.</p>
              <Accordion className="learn-more-collapse">
                <Card>
                    <Card.Header>
                      {showResults ?<Accordion.Toggle as={Button} onClick={onClick} className="btn-1 learn-more-btn" variant="link" eventKey="0" >
                       LEARN MORE
                      </Accordion.Toggle> : null}

                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p>Xminds has worked with more than 750 businesses (and counting) in the past 16 years and we offer
                        our experience with the best expert talent pool in the industry to fulfil all your application development
                        needs. We deploy best practices, proven development methodologies and time-tested optimum delivery models for developing and building applications on a fixed time, fixed cost basis.</p></Card.Body>
                    </Accordion.Collapse>
                  </Card>
              </Accordion>
            </div>
            <div className="herostyle-btn">
              <button className="btn  play-btn" onClick={() => {modalRef.current.openModal()}}>
                <span className="herostyle-icon">
                  <img alt="icons" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDQxLjk5OSA0MS45OTkiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPgo8cGF0aCB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGQ9Ik0zNi4wNjgsMjAuMTc2bC0yOS0yMEM2Ljc2MS0wLjAzNSw2LjM2My0wLjA1Nyw2LjAzNSwwLjExNEM1LjcwNiwwLjI4Nyw1LjUsMC42MjcsNS41LDAuOTk5djQwICBjMCwwLjM3MiwwLjIwNiwwLjcxMywwLjUzNSwwLjg4NmMwLjE0NiwwLjA3NiwwLjMwNiwwLjExNCwwLjQ2NSwwLjExNGMwLjE5OSwwLDAuMzk3LTAuMDYsMC41NjgtMC4xNzdsMjktMjAgIGMwLjI3MS0wLjE4NywwLjQzMi0wLjQ5NCwwLjQzMi0wLjgyM1MzNi4zMzgsMjAuMzYzLDM2LjA2OCwyMC4xNzZ6IE03LjUsMzkuMDk1VjIuOTA0bDI2LjIzOSwxOC4wOTZMNy41LDM5LjA5NXoiIGZpbGw9IiNmZmZmZmYiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIHN0eWxlPSIiPjwvcGF0aD4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPC9nPgo8ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8L2c+CjxnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjwvZz4KPC9nPjwvc3ZnPg==" />
                </span>
                <span className="herostyle-txt">How we work</span>
              </button>
              <div className="award-logos">
                <a href="https://clutch.co/profile/xminds-infotech"target="_blank">
                 <img
                  src={clutchIcon}
                  className="img-fluid"
                  alt="Responsive"/>
                   </a>
                   <a target="_blank"href="https://topappfirms.co/developers/app-development-companies/">
                 <img
                  src={mobileappIcon}
                  className="img-fluid"
                  alt="Responsive"/>
                   </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <AppModal ref ={modalRef}/>
  </>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header