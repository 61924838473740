import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import "../css/bootstrap.min.css"
import "./header.css"
import logo from "../images/logo.png"
import logoc from "../images/logo-c.png"


function setMenuTrigger() {
  const menuButton = document.getElementsByClassName("second-nav")[0];
  const navMenu = document.getElementsByClassName("collapse")[0];
  if (menuButton.className.includes("active")) {
    menuButton.className = menuButton.className.replace("active", "");
    navMenu.style.display = "none";
  } else {
    menuButton.className += " active";
    navMenu.style.display = "block";
  }
}


const Navbar = ({index, data, location}) => (
    <>
<div className="header-nav-wrapper">
    <div className="first-nav">
        <div id="toolbar" className="thim-toolbar default style-overlay ">
          <div className="container-lg container-md">
        <div className="toolbar-inner">
            <div className="toolbar-left">
            <aside id="text-2" className="widget widget_text"> <div className="textwidget"><ul className="thim-block-toolbar-help-info">
                <li className="phone-info"><i className="fa fa-phone" aria-hidden="true"></i>Need help? Call us now: <Link className="num" to="/">(+91) 471 2477556</Link></li>
                <li className="email-info"><i className="ion ion-android-mail"></i><Link className="email-to" to="/">sales@xminds.com</Link></li>
            </ul>
            </div>
            </aside>
            </div>
            <div className="toolbar-right">
            <aside id="thim_layout_builder-6" className="widget widget_thim_layout_builder">
                <div className="bp-element bp-element-social-links default">
                <ul className="socials">
                    <li><Link to="https://www.facebook.com/Xminds.Solutions/" target="_blank"><i className="fa fa-facebook"></i></Link></li>
                    <li><Link to="https://twitter.com/Xminds" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                    <li><Link to="https://in.linkedin.com/company/xminds-infotech-pvt-ltd" target="_blank"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                    <li><Link to="https://www.instagram.com/iamxminds/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></Link></li>
                </ul>
                </div>
            </aside>
            </div>
        </div>
        </div>
        </div>
        </div>
      <div className="second-nav">
        <div className="container-lg container-md">
            <nav className="navbar navbar-expand-md navbar-dark bg-nav-white">
            <Link className="navbar-brand" to="/"><div className="logo">
              <img className="logo-c" src={logoc} alt="Xminds" />
              <img className="logo-d" src={logo} alt="Xminds" />
            </div></Link>
      <button onClick={setMenuTrigger} className="navbar-toggler  menu-trigger" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarsExampleDefault">
          {
            (location ==='projectDetails' || location ==='projectDetails-single' || location ==='privacyPolicy') ?<ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
                    </li>
                    <li className={location ==='projectDetails-single' ? "nav-item active" : "nav-item"}>
                        <Link className="nav-link" to="/#services">Services</Link>
                    </li>
                    <li className={location ==='projectDetails' ? "nav-item active" : "nav-item"}>
                        <Link className="nav-link" to="/#gallery">Our Works</Link>
                    </li>
                    <li className="nav-item ">
                        <Link className="nav-link" to="/#blog">Blog</Link>
                    </li>
                    <li className="nav-item ">
                        <Link  className="nav-link" to="/#contact">Contact Us</Link>
                    </li>
                </ul> :
                <ul className="navbar-nav ml-auto">
                    <li className={typeof window !== `undefined` && window.location.hash === "" ? "nav-item active" : "nav-item"}>
                        <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
                    </li>
                    <li className={typeof window !== `undefined` && window.location.hash === "#services" ? "nav-item active" : "nav-item"}  >
                        <Link className="nav-link" to="/#services">Services</Link>
                    </li>
                    <li className={typeof window !== `undefined` && window.location.hash === "#gallery" ? "nav-item active" : "nav-item"}>
                        <Link className="nav-link" to="/#gallery">Our Works</Link>
                    </li>
                    <li className={typeof window !== `undefined` && window.location.hash === "#blog" ? "nav-item active" : "nav-item"}>
                        <Link className="nav-link" to="/#blog">Blog</Link>
                    </li>
                    <li className={typeof window !== `undefined` && window.location.hash === "#contact" ? "nav-item active" : "nav-item"}>
                        <Link  className="nav-link" to="/#contact">Contact Us</Link>
                    </li>
                </ul>
          }

      </div>
    </nav>
        </div>
           </div>
</div>
    </>
)
Navbar.propTypes = {
    siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
    siteTitle: ``,
}

export default Navbar