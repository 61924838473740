/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import { Link } from "gatsby"
import React from "react"
import "./footer.css"
import logo from "../images/logo.png"

const Footer = () => {
  return (
    <footer className="footer" id="footer-fixed">
      <div className="footer-main">
        <div className="container-lg container-md">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="widget widget-text">
                <div className="logo logo-footer">
                  <Link className="navbar-brand" to="/">
                    <div className="logo">
                      <img src={logo} alt="Xminds" />
                    </div>
                  </Link>
                </div>
                <p>
                  Xminds has worked with more than 750 businesses (and counting)
                  in the past 16 years and we offer our experience with the best
                  expert talent pool in the industry to fulfil all your
                  application development needs. We deploy best practices,
                  proven development methodologies and time-tested optimum
                  delivery models for developing and building applications on a
                  fixed time, fixed cost basis.”
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="widget widget-links">
                <h5 className="widget-title">Work With Us</h5>
                <ul>
                  <li>
                    <Link to="/#services">Services</Link>
                  </li>
                  <li>
                    <Link to="/#blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/#contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="widget widget-text widget-links">
                <h5 className="widget-title">Sales & Support</h5>
                <div className="bp-element bp-element-social-links default">
                  <ul className="socials con-number">
                    <li>
                      <span>Contact:</span>+91 471 2477556
                    </li>
                    <li>
                      <span>Email:</span>sales@xminds.com
                    </li>
                  </ul>
                </div>
              </div>
              <div className="widget widget-text widget-links follow-us">
                <h5 className="widget-title">Follow Us</h5>
                <div className="bp-element bp-element-social-links default">
                  <ul className="socials">
                    <li>
                      <Link
                        to="https://www.facebook.com/Xminds.Solutions/"
                        target="_blank"
                      >
                        <i className="fa fa-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://twitter.com/Xminds" target="_blank">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://in.linkedin.com/company/xminds-infotech-pvt-ltd"
                        target="_blank"
                      >
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.instagram.com/iamxminds/"
                        target="_blank"
                      >
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <div className="service-link">
                <Link to="/">Terms of Service </Link>
                <a href="/privacyPolicy" target="_blank">
                  | Privacy Policy
                </a>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="copy-right text-right">
                Copyright © {new Date().getFullYear()},
                <Link to="/"> Xminds</Link>. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
